/** @jsx jsx */
import "../scss/ckeditor-styles.scss";

import {css, jsx} from "@emotion/core";
import Layout from "../components/layout";
import SEO from "../components/seo";
import {DgsPlayer} from "../components/dgsplayer";

const pageStyle = css`
    font-size: 1rem;
    width: 100%;
    article {
        width: 80%;
        @media screen and (max-width: 1024px) {
            width: 100%;
            padding: 0 1rem;
            box-sizing: border-box;
        }
        img {
            max-width: 100%;
        }
    }
`;

const StaticPage = ({pageContext}) => {

    return <Layout longPage={true}>
        <SEO title={pageContext.page.title} />
        <div css={pageStyle} dangerouslySetInnerHTML={{__html: pageContext.page.markup}} />
    </Layout>
};

export default StaticPage;